const retrieveParamsFromUrl = (url: string) => {
  // ex url : http://localhost:3000/play/shopify/account#customer_id=${customer_id}#shop=raphtestloyoly.myshopify.com
  const { searchParams } = new URL(document.URL)
  const blocks = url.split('#')
  let preview = false
  let customerId = ''
  let shopName = ''
  const langCode = searchParams.get('langcode')
  if (!url.includes('#preview')) {
    if (blocks.length > 1) customerId = blocks[1].replace('customer_id=', '')
    if (blocks.length > 2) shopName = blocks[2].replace('shop=', '')
    const { searchParams } = new URL(document.URL)
    if (searchParams.has('customer_id')) {
      customerId = searchParams.get('customer_id') || ''
    }
    if (searchParams.has('shop')) {
      shopName = searchParams.get('shop') || ''
    }
  } else {
    // ex url : http://localhost:3000/play/shopify/account#preview=true#shop=raphtestloyoly.myshopify.com
    preview = true
    if (blocks.length > 2) shopName = blocks[2].replace('shop=', '')
  }
  return {
    preview,
    customerId,
    shopName,
    langCode
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  const { setIsEmbeddedStore, fetchLocales } = useStoreStore()
  const { authenticateFromStore, logout } = useStoreAuth()

  const { customerId, shopName, preview, langCode } = retrieveParamsFromUrl(to.fullPath)

  logout()
  const company = await useStoreStore().fetchStoreFromName(shopName)
  useStoreStore().langCode = langCode
  if (company) {
    const companyId = company.company_id
    useStoreApp().companyId = companyId
    setIsEmbeddedStore(companyId, true)

    await fetchLocales(companyId)

    if (!preview) {
      await authenticateFromStore(companyId, customerId)
    }
  } else {
    return navigateTo('https://www.loyoly.io', { external: true })
  }
})
